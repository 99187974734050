import { combineReducers } from '@reduxjs/toolkit';
import alert from './alert';
import auth from './auth';
import data from './data';
import master from './master';
import dashboard from './dashboard';
import home from './home';

// Reducer for isMobile with default value false

const defaultIsMobile = typeof window !== 'undefined' ? window.innerWidth <= 600 : false;
const isMobile = (state = defaultIsMobile, action) => {
  switch (action.type) {
    case 'SET_IS_MOBILE':
      return action.payload;
    default:
      return state;
  }
};

export default combineReducers({
  alert,
  auth,
  data,
  master,
  dashboard,
  home,
  isMobile
});
