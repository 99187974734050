import axios from 'axios';

const setToken = (token, orgID) => {
  if (token) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    axios.defaults.headers.common['OrganizationID'] = orgID?.toString();
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
};

export default setToken;
